@import '../../../../../../theme/colors.scss';
.not-popup {
  .SeasonPopUp-header {
    display: none !important;
  }
  h3 {
    display: none !important;
  }
  .season-form {
    width: 100%;
  }
  .SeasonPopUp-content {
    width: 100% !important;
  }
  .submit-btn {
    text-align: right;
  }
}
.SeasonPopUp {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(92, 219, 148, 0.5);
}
.SeasonPopUp-content {
  background-color: $white;
  padding: 1rem;
  border-radius: 1rem;
  width: 25%;
  .SeasonPopUp-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      cursor: pointer;
    }
  }
  h3 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: $text-colr;
  }
}
