@import './theme/colors.scss';

* {
    box-sizing: border-box;
}
* .rounded-border-btn {
    cursor: pointer;
    text-transform: uppercase;
    outline: 0;
    color: $light-green;
    border: 1px solid $light-green;
    border-radius: 25px;
    font-size: 13px;
    background-color: white;
    padding: 7px 15px 5px;
}

// common style -----
.text-uppercase {
    text-transform: uppercase;
}
strong {
    font-weight: 600;
}
.mt16 {
    margin-top: 16px;
}
.main-content {
    padding: 24px 28px;
    background-color: #f9f9f9;
    width: 100%;
    .main-heading-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
        .main-heading {
            color: $dark-blue;
            text-transform: capitalize;
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
        }
    }
}
// btn style -----
.btn {
    padding: 8px 20px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 30px;
    border: none;
    font-weight: 700;
    letter-spacing: 0.4px;
    cursor: pointer;
    text-transform: capitalize;
    &-block {
        width: 100%;
    }
    &-lg {
        padding: 12px 24px;
        font-size: 18px;
        line-height: 24px;
    }
    &-primary {
        background-color: $light-green;
        color: $white;
        transition: all 0.2s ease-in;
        &:hover {
            background-color: rgba($light-green, 0.8);
        }
    }
    &-secondary {
        background-color: transparent;
        color: $light-green;
        border: 1px solid $light-green;
    }
}
.w-100 {
    width: 100%;
}

// form style -----
form {
    width: 100%;
}
.form-group {
    width: 100%;
    position: relative;
    &:not(:first-child) {
        margin-top: 22px;
    }
    .input-group {
        width: 100%;
        outline: 0;
        border: 1px solid $light-green;
        padding: 10px 12px;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        &.error {
            border: 1px solid $light-red;
        }
    }
    .error-msg {
        color: $light-red;
        position: absolute;
        right: 0;
        top: calc(100% + 2px);
        font-size: 11px;
        font-weight: 600;
    }
}
.floating-input {
    position: relative;
    .input-label {
        position: absolute;
        color: #ccc;
        left: 16px;
        top: 11px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
    }
    .input-group {
        &:focus,
        &:valid {
            + .input-label {
                top: -9px;
                background-color: #fff;
                padding: 0 4px;
                color: #5dda94;
                left: 12px;
            }
        }
    }
    &.w50 {
        width: calc(50% - 8px);
        display: inline-block;
        &:nth-child(2n) {
            margin-left: 16px;
        }
    }
}

.ellipsis {
    text-overflow: ellipsis;
}
.overflow {
    white-space: nowrap;
    overflow: hidden;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 480px) {
    .main-content {
        padding: 15px 15px;
    }
    .floating-input.w50 {
        width: 100%;
        display: block;
    }
    .floating-input.w50:nth-child(2n) {
        margin-left: 0;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .main-content {
        padding: 15px 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .main-content {
        padding: 24px 28px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .main-content {
        padding: 24px 28px;
    }
}

/* Extra large devices (medium laptops and desktops) */
@media only screen and (min-width: 1200px) {
    .main-content {
        padding: 24px 28px;
    }
}
/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: 1400px) {
    .main-content {
        padding: 24px 28px;
    }
}
