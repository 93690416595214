@import '../../../../../theme/colors.scss';

.upload-percentage-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: $white;
    .single-chart {
        width: 100%;
        justify-content: space-around;
    }

    .circular-chart {
        display: block;
        margin: 10px auto;
        max-width: 100%;
        max-height: 100px;
    }

    .circle-bg {
        fill: none;
        stroke: $grey-shade;
        stroke-width: 3.8;
    }

    .circle {
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    .circular-chart.orange .circle {
        stroke: $light-green;
    }

    .circular-chart.green .circle {
        stroke: $green;
    }

    .circular-chart.blue .circle {
        stroke: $light-blue;
    }

    .percentage {
        font-family: sans-serif;
        font-size: 8px;
        text-anchor: middle;
        font-weight: 600;
    }
}
