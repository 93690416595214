@import '../../../../theme/colors.scss';
.sidebar {
  position: sticky;
  top: 72px;
  height: calc(100vh - 72px);
  min-width: 250px;
  overflow-y: auto;
  z-index: 3;
  background: $white;
  box-shadow: 0 12px 12px $light-gainsborot;
  border-top: 1px solid #eaeaea;
  .sidebar-list {
    margin-top: 50px;
    li {
      margin-top: 0.5rem;
      a,
      .tab-item {
        display: inline-block;
        padding: 16px 16px 16px 40px;
        width: 90%;
        box-sizing: border-box;
        text-decoration: none;
        text-transform: capitalize;
        border-radius: 0 2rem 2rem 0;
        color: $green;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        font-weight: 500;
        &.active {
          background: $light-green;
          color: $white;
        }
        &:hover {
          background: $light-green;
          color: $white;
        }
      }
    }
  }
}
