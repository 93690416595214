.dashboard {
    .dashboard-main {
        display: flex;
        .dashboard-right-sec {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
    }
}
