@import '../../../../../theme//colors.scss';

.video-assets {
    display: flex;
    .cast-list {
        display: block;
        max-height: 300px;
        overflow-y: auto;
        &.grid-template {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .artist {
            width: auto;
            max-width: 100%;
        }
    }
    .assets-left-section {
        padding: 0 2rem 0 1rem;
        flex: 0.5;
        border-right: 5px solid $light-green;
        .cast-and-crew {
            margin-top: 1rem;
            h3 {
                font-weight: 600;
                color: $dark-blue;
                padding: 1rem 0;
            }
        }
    }
    .asset-cards {
        flex: 0.5;
        .assets-flex-group {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
        }
    }
    .asset-options {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        button {
            margin-left: 1rem;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}
@media (max-width: 768px) {
    .video-assets {
        display: block;
        .assets-left-section {
            padding: 0 1rem 2rem 1rem;
            flex: 0.5 1;
            border-right: 0;
            border-bottom: 5px solid #5ddb94;
        }
        .asset-cards {
            margin-top: 2rem;
        }
        .asset-options {
            flex-wrap: wrap;
            button {
                margin-bottom: 10px;
            }
        }
    }
}
