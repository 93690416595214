@import '../../../../theme/colors.scss';

.main-content {
  .main-heading {
    font-weight: bold;
  }
  .type-of-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    input[type='radio'] {
      width: 0;
      height: 0;
    }
    input[type='radio']:checked + label .card-type {
      background-color: $light-green;
      color: $white;
      path {
        fill: $white;
      }
      span {
        color: $white;
      }
    }
    .card-type {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      padding: 1rem 2rem;
      border-radius: 10px;
      cursor: pointer;
      span {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
        color: $light-green;
      }
      &:last-child {
        margin-left: 1rem;
      }
      &:hover {
        background-color: $light-green;
        color: $white;
        path {
          fill: $white;
        }
        span {
          color: $white;
        }
      }
    }
  }
  .videolist {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .video-cards {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
    }
    .load-more {
      width: 100%;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
