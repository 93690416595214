@import '../../../../../theme/colors.scss';
.artist {
  width: 300px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 0 3px #ddd;
  flex: 1 1 calc(33% - 10px);
  margin: 5px;
  max-width: calc(33% - 10px);
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 0;
  &.disabled::before {
    content: '';
    position: absolute;
    background-color: $light-green;
    opacity: 0.2;
    z-index: 1;
    cursor: no-drop;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    background-color: rgba(#5ddb94, 0.1);
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    object-fit: contain;
  }
  .no-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-green;
    span {
      font-weight: 900;
      font-size: 1rem;
      color: $green;
    }
  }
  p {
    font-size: 14px;
    line-height: 16px;
    padding: 0 12px;
    flex: 1;
  }
}
.add-artists {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .search-box {
    display: flex;
    input[type='search'] {
      outline: 0;
      border-radius: 20px;
      padding: 0.2rem 1rem;
      border: 1px solid $light-green;
    }
    button {
      margin-left: 24px;
    }
  }
  .search-result-container {
    width: 100%;
    max-height: 45vh;
    overflow: auto;
  }
  .search-result {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
    .artists-bottom-sec {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
    }
    .artist-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
    .no-artist {
      width: 100%;
      margin: 0 auto;
      h3 {
        width: 100%;
        text-align: center;
      }
      .add-new-artists {
        margin-top: 16px;
        .action-row {
          margin-top: 24px;
        }
      }
    }
  }
  .add-artist-form {
    margin-top: 1rem;
    width: 100%;
    .action-row {
      margin-top: 1rem;
    }
  }
  .profile-pic {
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    input[type='file'] {
      height: 0;
      width: 0;
    }
    label {
      width: 100%;
      text-align: center;
    }
    .upload-on-bucket {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
  .upload-preview {
    text-align: center;
  }
}
@media (max-width: 600px) {
  .add-artists .search-result-container {
    max-height: 80vh;
  }
}
