@import '../../../../theme/colors.scss';

.form-popup {
    background-color: $white;
    z-index: 99;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-title {
    text-align: center;
    color: $dark-blue;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 40px;
}
.form-max-height {
    max-height: calc(100vh - 100px);
    max-width: 500px;
}
.form-wrapper {
    overflow: auto;
    margin: 0 auto;
    padding-top: 10px;
    .text-right {
        text-align: right;
    }
    .text-center {
        text-align: center;
    }
    .action-row {
        margin-top: 40px;
        button {
            &:last-child {
                margin-left: 12px;
            }
        }
    }
}

@media (max-width: 560px) {
    .form-popup {
        overflow: auto;
        padding: 5px 1rem 2rem;
    }
    .form-title {
        margin-bottom: 10px;
    }
    .form-popup .meta-form {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 0;
    }
    .form-popup .bottom-sec {
        min-height: 10vh;
        padding-bottom: 5vh;
    }
    .form-popup .meta-form .form-control:last-child {
        margin-bottom: 1rem;
    }
    .form-wrapper .action-row {
        margin-top: 20px;
    }
}
