@import '../../../../theme//colors.scss';

.content-card {
  border: 2px solid $light-green;
  border-radius: 10px;
  margin: 12px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 4px 4px 6px -2px lightgrey;
  .card-body-desc {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 12px;
    overflow-y: scroll;
    word-break: break-word;
    min-height: 90px;
    max-height: 90px;
  }
  .thumbnail-container {
    display: flex;
    .thumbnail {
      width: 100%;
      object-fit: contain;
      min-height: 137.83px;
    }
  }
  .card-body {
    padding: 12px;
    &-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 12px;
      text-transform: capitalize;
    }
    &-desc {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
      word-break: break-all;
      span {
        color: $light-green;
        padding-left: 4px;
        cursor: pointer;
        &:hover {
          color: rgba($light-green, 0.7);
        }
      }
    }
  }
  .content-text {
    font-size: 14px;
    color: #777;
    word-wrap: break-word;
    margin-bottom: 0.6rem !important;
    .read-or-hide {
      cursor: pointer;
      color: $light-green;
      &:hover {
        border-bottom: 1px solid $light-green;
      }
    }
  }
  .content-actions {
    padding: 12px;
    padding-top: 0;
    .content-active {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      p {
        color: $green;
        border-radius: 20px;
      }
      .Mui-checked,
      .MuiSwitch-track {
        color: $light-green;
      }
      .css-1nr2wod.Mui-checked + .MuiSwitch-track {
        background: $light-green;
      }
      .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
        + .MuiSwitch-track {
        background: $light-green;
      }
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 480px) {
  .content-card {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .content-card {
    width: 45%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content-card {
    width: 45%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .content-card {
    width: 30%;
  }
}

/* Extra large devices (medium laptops and desktops) */
@media only screen and (min-width: 1200px) {
  .content-card {
    width: 30%;
  }
}
/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: 1400px) {
  .content-card {
    width: 22%;
  }
}
