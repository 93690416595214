@import '../../../../theme/colors.scss';

.login{
    width: 100%;
    height: 100vh;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    .login-form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 100px;
        @media (max-width: 768px) {
            width: 100%;
        }
        .login-form-left-sec{
            flex: 1 1 50%;
            text-align: center;
            max-width: 50%;
            img{
                max-width: 100%;
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
        .login-form-right-sec{
            flex: 1 1 50%;
            display: flex;
            flex-direction: column;
            .auth-btn{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 4rem;
                a{
                    text-decoration: none;
                    font-size: 1.2rem;
                    color: $dark-blue;
                    font-weight: 600;
                    padding: 1.2rem 2rem;
                    border-radius: 0.5rem;
                    flex: 1;
                    text-align: center;
                    transition: all .3s ease-in-out;
                    &.active{
                        background-color: $dark-blue;
                        color: $white;
                    }
                }
            }
            .action-row{
                margin-top: 48px;
            }
        }
    }
    .copyright{
        font-size: 14px;
        color: $light-grey;
    }
}