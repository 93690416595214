@import '../../theme/colors.scss';

.error-boundary {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background: $green-opacity;
    .error-flex-item {
        border: 1px solid $dark-blue;
        max-width: 30%;
        border-radius: 10px;
        background: $white;
        padding: 1.5rem;
        .error-message {
            color: $dark-blue;
            h3 {
                font-size: 2rem;
                margin: 0 0 1.2rem;
            }
            p {
                font-size: 1rem;
                margin: 2.5rem 0;
            }
        }
        .error-close {
            text-align: center;
            margin-top: 2rem;
            button {
                background-color: $dark-blue;
                color: $white;
                outline: 0;
                cursor: pointer;
                border: 0;
                font-size: 14p;
                border-radius: 1rem;
                padding: 0.5rem 1.5rem;
            }
        }
    }
}
