@import '../../../../theme/colors.scss';

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        width: 100px;
    }
    a {
        padding: 0;
        background-color: transparent;
        outline: 0;
        border: 0;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;
        color: $dark-blue;
    }
}
