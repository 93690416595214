@import '../../theme/colors.scss';
.season-collection {
  position: fixed;
  left: 250px;
  top: 72px;
  z-index: 1;
  background: #fff;
  width: calc(100% - 250px);
  height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .heading {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: $dark-blue;
    margin-top: 1rem;
  }
  .left-sec {
    flex: 0.5;
    margin: 1rem 0 1rem 0;
    padding: 0 1rem 0 1rem;
    border-right: 5px solid $light-green;
    .back {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      svg {
        cursor: pointer;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 1rem;
      color: $dark-blue;
    }
  }
  .right-sec {
    flex: 0.5;
    margin: 1rem;
  }
}
