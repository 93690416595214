@import '../../../../../theme/colors.scss';
.monitization {
  padding: 1rem 2rem;
  width: 100%;
  color: $dark-blue;
  .heading {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .monitization-cards {
    display: flex;
    align-items: center;
    justify-content: left;
    .card {
      margin-right: 1rem;
      input[type='radio'] {
        height: 0;
        width: 0;
      }
      label {
        border: 2px solid $light-green;
        border-radius: 4px;
        padding: 0.5rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 30px;
          margin-right: 1rem;
        }
      }
      input[type='radio']:checked + label {
        background-color: $light-green;
      }
      input[type='radio']:checked + img {
        color: $white;
      }
    }
  }
}
