@import '../../../../theme/colors.scss';

.signup {
    width: 100%;
    height: 100vh;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .coming-soon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        color: $dark-blue;
        font-size: 4rem;
        background: rgba(93, 219, 148, 0.99);
        .back-to-login {
            position: absolute;
            font-size: 1rem;
            text-transform: uppercase;
            color: $dark-blue;
            top: 40px;
            right: 40px;
            padding: 12px 0;
            cursor: pointer;
            background-color: transparent;
            border: 0;
        }
        .close {
            position: absolute;
            top: 40px;
            right: 40px;
            padding: 12px 0;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            &:before,
            &:after {
                content: '';
                width: 4px;
                height: 24px;
                top: 0;
                background-color: #06396a;
                position: absolute;
                border-radius: 2px;
            }
            &:before {
                left: -3px;
                transform: rotate(-45deg);
            }
            &:after {
                right: -1px;
                transform: rotate(45deg);
            }
        }
    }
    .signup-form {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 1rem;
        .signup-form-left-sec {
            flex: 0.5;
            text-align: center;
            img {
                width: 60%;
            }
        }
        .signup-form-right-sec {
            display: flex;
            flex: 0.5;
            align-items: center;
            justify-content: center;
            .form-container {
                width: 70%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                box-shadow: 0px 1px 5px $green;
                padding: 2rem 1.8rem;
                border-radius: 10px;
                .signup-heading {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1rem;
                    h3 {
                        color: $dark-blue;
                        margin: 0;
                        font-size: 1.8rem;
                    }
                }
                .action-row {
                    margin-top: 48px;
                }
            }
        }
    }
}
