@import '../../../../../../theme/colors.scss';

.video-asset-card {
    display: flex;
    padding: 16px;
    margin: 12px;
    flex-direction: row;
    border: 1px solid $light-green;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 4px 4px 6px -2px lightgrey;
    .asset-image {
        text-align: center;
        width: 100px;
        img {
            height: 100px;
            width: 100%;
            object-fit: contain;
        }
    }
    .asset-info {
        flex: 1;
        padding: 0 1rem;
    }
    .asset-heading {
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        padding-bottom: 4px;
        margin-top: 8px;
        text-transform: capitalize;
    }
    .asset-description {
        font-size: 14px;
        line-height: 20px;
        color: $light-grey;
    }
    .asset-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .MuiSvgIcon-root {
            color: $green;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            &:hover {
                transform: rotate(360deg);
            }
        }
    }
}
