@import '../../../theme/colors.scss';
.episode-collection {
  position: fixed;
  left: 250px;
  top: 72px;
  z-index: 2 !important;
  background: #fff;
  width: calc(100% - 250px);
  height: calc(100vh - 72px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
