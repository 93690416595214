@import '../../theme/colors.scss';

.cms-alert {
    position: fixed;
    z-index: 999;
    top: 2rem;
    right: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    max-width: 30%;
    .alert-message {
        padding: 0;
        margin: 0 1rem;
    }
    .close-alert {
        cursor: pointer;
    }
    &.success-alert {
        background-color: $green;
        border: 1px solid $green;
        box-shadow: 0 0 5px $green;
        color: $white;
    }
    &.error-alert {
        background-color: $error-red;
        border: 1px solid $error-red;
        box-shadow: 0 0 5px $error-red;
        color: $white;
    }
    &.info-alert {
        background-color: $dark-blue;
        border: 1px solid $dark-blue;
        box-shadow: 0 0 5px $dark-blue;
        color: $white;
    }
}
@media (max-width: 600px) {
    .cms-alert {
        max-width: 85%;
    }
}
