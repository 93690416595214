@import '../../../../../../theme/colors.scss';
.tag {
  box-shadow: 0 0 5px $light-grey;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  color: $dark-blue;
  cursor: pointer;
  .tag-info {
    .tag-name {
      margin-bottom: 15px;
      word-break: break-word;
    }
    .tag-url {
      margin-bottom: 5px;
      word-break: break-all;
      a {
        color: $dark-blue;
      }
    }
  }
  .tag-icon {
    svg {
      font-size: 2.4rem;
      color: $dark-blue;
    }
  }
}
