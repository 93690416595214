@import '../../../../theme/colors.scss';

.dashboard-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  background: $white;
  justify-content: space-between;
  padding: 16px 32px;
  box-shadow: 0 0 12px $light-gainsborot;
  .header-logo {
    img {
      width: 100px;
    }
  }
  .header-right-sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .header-search {
      margin-right: 2.5rem;
      input {
        width: 320px;
      }
    }
    .header-profile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 0 !important ;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
}
.header-profile-popup {
  min-width: 250px;
  max-width: 250px;
  background-color: $white;
  border-radius: 5px;
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
  .header-profile-info {
    padding: 1rem;
    border-bottom: 1px solid $light-gainsborot;
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-email {
      text-align: center;
      font-size: 14px;
      margin-top: 1rem;
    }
  }
  .header-profile-accounts {
    .MuiMenuItem-root {
      font-size: 16px;
      border-bottom: 1px solid $light-gainsborot;
      &:hover {
        background-color: $extra-light-green;
      }
      &.selectedItem {
        background-color: $extra-light-green;
      }
    }
  }
  .header-profile-logout {
    padding: 1rem;
    text-align: center;
    .MuiButton-root {
      width: auto;
    }
  }
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background-color: $light-green !important;
  color: $grey-shade !important;
  font-size: 12px !important;
  font-weight: bold !important;
  width: 100%;
  border: 0;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 480px) {
  .dashboard-header {
    padding: 16px 10px;
  }
  .dashboard-header .header-right-sec .header-search input {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .dashboard-header {
    padding: 16px 15px;
  }
  .dashboard-header .header-right-sec .header-search input {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .dashboard-header .header-right-sec .header-search input {
    width: 320px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .dashboard-header .header-right-sec .header-search input {
    width: 320px;
  }
}

/* Extra large devices (medium laptops and desktops) */
@media only screen and (min-width: 1200px) {
  .dashboard-header .header-right-sec .header-search input {
    width: 320px;
  }
}
/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: 1400px) {
  .dashboard-header .header-right-sec .header-search input {
    width: 320px;
  }
}
