@import '../../../../../theme/colors.scss';

.master-upload {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 91;
  top: 0;
  left: 0;
  background: $green-opacity;
  .master-content {
    width: 50%;
    height: 70vh;
    background: $white;
    border-radius: 0.8rem;
    padding: 1rem;
    .master-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      .upload-title {
        font-weight: 600;
        text-transform: capitalize;
        font-size: 1.3rem;
        color: $dark-blue;
        margin: 0;
        padding: 0;
      }
      .pointer {
        cursor: pointer;
      }
    }
    .file-upload-section {
      text-align: center;
      height: calc(100% - 52px);
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
        margin: 0 0 5%;
      }
      .img-sm {
        width: 40%;
      }
      .upload-percent {
        color: $dark-blue;
        font-weight: 400;
        font-size: 3rem;
      }
      h3 {
        font-weight: 500;
        margin-bottom: 5%;
        color: $dark-blue;
        font-size: 1.2rem;
      }
      input[type='file'] {
        height: 0;
        overflow: hidden;
        width: 0;
      }
      label,
      button {
        cursor: pointer;
        margin-bottom: 16px;
        padding: 0.5rem 3rem;
        border-radius: 0.5rem;
        font-weight: 600;
        background: $green;
        color: $white;
        text-transform: capitalize;
      }
      button {
        outline: 0;
        border: 0;
        padding: 0.5rem 4rem;
      }
    }
    .upload-progress-bar {
      position: relative;
      width: 100%;
      height: 20px;
      border-radius: 1rem;
      overflow: hidden;
      margin: 0 0 5%;
      .upload-progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
      .upload-progress-lower {
        background: $dark-blue;
        width: 100%;
      }
      .upload-progress-upper {
        width: 0;
        background: $light-green;
      }
    }
    .upload-progress {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 1rem;
      %remain-steps {
        &:before {
          content: counter(stepNum);
          font-family: inherit;
          font-weight: 700;
        }
        &:after {
          background-color: $light-green;
        }
      }
      .multi-steps {
        display: table;
        table-layout: fixed;
        width: 100%;
        > li {
          counter-increment: stepNum;
          text-align: center;
          display: table-cell;
          position: relative;
          color: $dark-blue;
          z-index: 2;
          &:before {
            content: '\f00c';
            content: '\2713;';
            content: '\10003';
            content: '\10004';
            content: '\2713';
            display: block;
            margin: 0 auto 4px;
            background-color: $white;
            width: 36px;
            height: 36px;
            line-height: 32px;
            text-align: center;
            font-weight: bold;
            padding: 3px 2px 0px;
            border: {
              width: 2px;
              style: solid;
              color: $dark-blue;
              radius: 50%;
            }
          }
          &:after {
            content: '';
            height: 2px;
            width: 100%;
            background-color: $dark-blue;
            position: absolute;
            top: 20px;
            left: 50%;
            z-index: -1;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }

          &.is-active {
            @extend %remain-steps;
            &:before {
              background-color: $white;
              border-color: $dark-blue;
            }

            ~ li {
              color: $dark-blue;
              @extend %remain-steps;
              &:before {
                background-color: $light-green;
                border-color: $light-green;
              }
            }
          }
          &.cursor-pointer {
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 480px) {
  .master-upload .master-content .file-upload-section {
    height: calc(100% - 52px);
  }
  .master-upload .master-content .file-upload-section img {
    width: 100%;
  }
}

@media (max-width: 599px) {
  .master-upload {
    height: 100vh;
    width: 100%;
  }
  .master-upload .master-content {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .master-upload .master-content {
    width: 80%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .master-upload .master-content .file-upload-section {
    height: 100%;
  }
  .master-upload .master-content .file-upload-section img {
    width: 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .master-upload .master-content .file-upload-section {
    height: 100%;
  }
  .master-upload .master-content .file-upload-section img {
    width: 60%;
  }
  .master-upload .master-content {
    width: 50%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .master-upload .master-content .file-upload-section {
    height: calc(100% - 130px);
  }
}

/* Extra large devices (medium laptops and desktops) */
@media only screen and (min-width: 1200px) {
  .master-upload .master-content .file-upload-section {
    height: calc(100% - 150px);
  }
}
/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: 1400px) {
  .master-upload .master-content .file-upload-section {
    height: calc(100% - 100px);
  }
}
