@import '../../theme/colors.scss';

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: $light-green-opacity;
    .MuiCircularProgress-root {
        font-size: 3rem;
        width: 50px;
        color: $dark-blue;
    }
}
