@import '../../../../../../theme/colors.scss';

.asset-preview {
  display: flex;
  padding: 16px;
  margin: 12px;
  flex-direction: row;
  border: 1px solid $light-green;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 6px -2px lightgrey;
  .subtitle-file-icon {
    font-size: 5rem;
    color: $light-green;
  }
  video,
  img {
    max-width: 200px;
    border-radius: 10px;
  }
  h3 {
    flex: 1;
    padding: 0 1rem;
    text-transform: capitalize;
    font-weight: 600;
    color: $dark-blue;
  }
}
.no-preview {
  padding: 1rem;
  font-weight: 600;
  color: $dark-blue;
  text-align: center;
}
