$dark-blue: rgba(6, 57, 106, 1);
$light-green: rgba(93, 219, 148, 1);
$extra-light-green: rgba(93, 219, 148, 0.3);
$light-green-opacity: rgba(93, 219, 148, 0.5);
$light-grey: rgba(158, 158, 158, 1);
$gainsborot-grey: rgba(180, 180, 180, 1);
$text-colr: rgba(63, 61, 86, 1);
$grey-text: rgba(193, 193, 193, 1);
$light-gainsborot: rgba(211, 211, 211, 1);
$grey-shade: rgba(238, 238, 238, 1);
$white: rgba(255, 255, 255, 1);
$light-red: rgb(255, 78, 78);
$error-red: rgba(247, 104, 104, 1s);
$green: rgba(56, 149, 131, 1);
$dark-green: rgba(24, 127, 86, 1);
$green-opacity: rgba(56, 149, 131, 0.5);
$light-blue: rgba(60, 158, 229, 1);
