.content {
  margin-top: 1rem;
  .content-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading {
      margin-bottom: 10px;
    }
  }
  .tags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    img {
      margin-right: 1rem;
      width: 20px;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 480px) {
  .monitization .content .tags {
    grid-template-columns: 1fr;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .monitization .content .tags {
    grid-template-columns: 1fr 1fr;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .monitization .content .tags {
    grid-template-columns: 1fr 1fr;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .monitization .content .tags {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Extra large devices (medium laptops and desktops) */
@media only screen and (min-width: 1200px) {
  .monitization .content .tags {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: 1400px) {
  .monitization .content .tags {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
