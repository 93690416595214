@import '../../../../theme/colors.scss';
.update-episode {
  .SeasonPopUp-header {
    display: none !important;
  }
  h3 {
    display: none !important;
  }
  .SeasonPopUp-content {
    width: 100% !important;
    .submit-btn {
      text-align: right;
    }
  }
}
