@import '../../theme/colors.scss';
.page-not-found{
    color: $dark-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .text-content{
        width: 320px;
        text-align: center;
        h1{
            font-size: 8rem;
            margin: 0;
        }
        h2{
            font-size: 1.8rem;
        }
        p{
            font-size: 1.2rem;
            a{
                color: $light-green;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}