@import '../../../../../../theme/colors.scss';
.adtags {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 2rem;
  button {
    background: none;
    cursor: pointer;
    border: 0;
  }
  .left-poster {
    flex: 0.5;
    padding-right: 2rem;
    .left-sec {
      display: flex;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      button {
        margin-top: 2rem;
      }
      .img-fluid {
        height: calc(100vh - 4rem);
        padding-right: 3rem;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }
  .tags-form {
    flex: 0.5;
    border-left: 4px solid $light-green;
    padding: 1rem;
    color: $dark-blue;
    position: relative;
    .form-heading {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .form-sub-heading {
      margin-bottom: 2rem;
    }
    p {
      font-size: 13px;
    }
    form {
      .form-control {
        position: relative;
        padding: 5px 0;
        p {
          position: absolute;
          background-color: $white;
          margin-left: 2rem;
          top: -2px;
          padding: 0 10px;
          color: $light-red;
        }
      }
      input {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: 2rem;
        border: 1px solid $light-green;
        &.error {
          border: 1px solid $light-red;
        }
        &:focus {
          border: 1px solid $dark-green;
        }
        &:focus-visible {
          border: 1px solid $dark-green;
        }
      }
      .p-relative {
        position: relative;
        .p-abs {
          position: absolute;
          display: flex;
          flex-direction: column;
          padding: 1rem 0 4rem;
          .top {
            flex-shrink: 0;
          }
          .middle {
            overflow-y: scroll;
            display: grid;
            margin-top: 1rem;
            grid-template-columns: 1fr 1fr 1fr;
          }
          .submit-sec {
            flex-shrink: 0;
            text-align: right;
            position: absolute;
            bottom: 0;
            width: 100%;
            button {
              border: 1px solid #5ddb94;
            }
          }
        }
      }
      .select-content {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        .content-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            color: $light-green;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .adtags .tags-form form .p-relative .p-abs .middle {
  }
}
